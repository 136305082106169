import React from 'react';
import {getOneDistrict} from "easy-address-react";
const Test = () => {
    const data = getOneDistrict(1)
    console.log(data)
    return (
        <div>
            
        </div>
    );
};

export default Test;