import React, {useState} from "react";
import {baseUrl, errorNotify, successNotify} from "../../utils/helper";
import {AiOutlineLoading3Quarters, AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import useData from "../../hooks/useData";
import "react-toastify/dist/ReactToastify.css";
import Rodal from "rodal";
import "rodal/lib/rodal.css";

const Product = ({
                     name,
                     price,
                     quantity,
                     imageURL,
                     id,
                     update,
                     setUpdate,
                 }) => {
    const {token} = useData();
    const [count, setCount] = useState(1);
    const [visible, setVisible] = useState(false);
    const [productId, setPorductID] = useState();
    const [loading,setLoading] = useState(false)
    const increment = () => {
        if (count < quantity) {
            setCount(count + 1);
        } else {
            errorNotify("You cannot order more than the available stock quantity.");
        }
    };

    // DECREMENT
    const decrement = () => {
        if (count > 1) {
            setCount(count - 1);
        }
    };
    //PLACE ORDER successfully functions
    const orderPlace = async () => {

        const postData = {
            product_id: productId,
            quantity: count,
        };
        if (count > 10) {
            errorNotify("Quantity must be 10 or less");
            return;
        }
        if (quantity <= 0) {
            errorNotify("Product is not in-stock");
            return;
        }
        try {
            setLoading(true)
            const res = await fetch(`${baseUrl.url}/api/product_purchase`, {
                method: "POST",
                credentials: "include",
                headers: {
                    Authorization: `bearer ${token}`,
                },
                body: JSON.stringify(postData),
            });
            const resData = await res.json()
            if (res.ok) {
                successNotify("Order Placed Successfully!");
                setUpdate(!update);
                setVisible(!visible)
                setCount(1)
            } else {
                errorNotify(resData?.errors[0]);
            }
        } catch (error) {
            console.log(error);
        }finally {
            setLoading(false)
        }
    };
    return (
        <div className={`p-5 border border-gray-200 rounded-xl ${quantity <= 0 && 'cursor-not-allowed'}`}>
            <Rodal
                visible={visible}
                onClose={() => {
                    setVisible(!visible);
                }}
                animation={"zoom"}
                customStyles={{
                    minWidth: "350px",
                    height: "auto",
                    width: "20%",
                    bottom: "auto",
                    top: "50%",
                    transform: "translateY(-50%)",
                }}
            >
                <div className="p-2">
                    <h3 className="text-gray-700">
                        Are you agree to order?
                    </h3>
                    <p className={'text-sm text-gray-600'}>Think again,take your time to confirm your order</p>
                    <div className="flex justify-end items-center mt-3">
                        <button
                            className="text-sm w-1/2 bg-red-500 text-white py-2 px-3 rounded"
                            onClick={() => {
                                setVisible(!visible);
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className={`ml-2 text-sm w-1/2 bg-green-500 text-white py-2 px-3 rounded flex items-center justify-center`}
                            onClick={() => orderPlace()}
                        >
                            {loading && <AiOutlineLoading3Quarters className='mr-2 spin360'/>}Yeah,Order!
                        </button>
                    </div>
                </div>
            </Rodal>
            <div className="w-full h-28 rounded-xl overflow-hidden">
                <img
                    src={`${baseUrl.url}${imageURL}`}
                    alt=""
                    className={`w-full h-full object-cover ${quantity <= 0 && 'grayscale'}`}
                />
            </div>
            <h1 className="text-base text-center">{name?.slice(0, 15)}{name.length > 15 && '...'}</h1>
            <div className="block lg:flex items-center justify-between">
                <p className={`text-center lg:text-start text-sm font-semibold`}>Stock: {quantity}</p>
                <p className="text-center lg:text-end text-sm font-semibold">৳ {price}/Piece</p>
            </div>

            <div className="grid grid-cols-12 items-center overflow-hidden my-1 w-full lg:w-8/12 mx-auto">
                <button disabled={quantity <= 0}
                        onClick={decrement}
                        className="h-full w-full p-1 col-span-3 bg-gray-100 flex items-center justify-center border-r"
                >
                    <AiOutlineMinus/>
                </button>
                <input
                    type="button"
                    className="col-span-6 p-1 dark:text-white"
                    value={count}
                />
                <button disabled={quantity <= 0}
                        onClick={increment}
                        className="h-full w-full p-1 col-span-3 bg-gray-100 flex items-center justify-center border-l"
                >
                    <AiOutlinePlus/>
                </button>
            </div>

            <button
                disabled={quantity <= 0}
                className={`w-full rounded-xl py-2 px-2 transition-all duration-500 font-bold ${quantity <= 0 ? `bg-gray-100 text-gray-700` : `bg-blue-500 hover:bg-blue-700 text-white hover:text-white`}`}
                onClick={() => {
                    setVisible(!visible);
                    setPorductID(id);
                }}
            >
                Order Now
            </button>
        </div>
    );
};

export default Product;
