import Dashboard from "../Layout/Dashboard/Dashboard";
import {createBrowserRouter} from "react-router-dom";
import Login from "../Pages/Login/Login";
import Home from "../Pages/Home/Home";
import AddProduct from "../Pages/AddProduct/AddProduct";
import AddReview from "../Pages/AddReview/AddReview";
import UserRoute from "../UserRoute/UserRoute";
import StockRequest from "../Pages/StockRequest/StockRequest";
import ServiceRequest from "../Pages/ServiceRequest/ServiceRequest";
import OrderList from "../Pages/OrderList/OrderList";
import AllProductContainer from "../Pages/AllProductContainer/AllProductContainer";
import Reviews from "../Pages/Reviews/Reviews";
import NotFound from "../Pages/NotFound/NotFound";
import AddUser from "../Pages/AddUser/AddUser";
import SuperAdminRoute from "../SuperAdminRoute/SuperAdminRoute";
import CommonRoute from "../CommonRoute/CommonRoute";
import AdminRoute from "../AdminRoute/AdminRoute";
import Profile from "../Pages/Profile/Profile";
import Revenue from "../Pages/Revenue/Revenue";
import Test from "../Pages/Test/Test";

export const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <CommonRoute>
                <Dashboard/>
            </CommonRoute>
        ),
        children: [
            {
                path: "/",
                element: (
                    <CommonRoute>
                        <Home/>
                    </CommonRoute>
                ),
            },
            {
                path: "/test",
                element: (
                    <Test/>
                ),
            },
            {
                path: "/add-product",
                element: (
                    <AdminRoute>
                        <AddProduct/>
                    </AdminRoute>
                ),
            },
            {
                path: "/add-review",
                element: (
                    <UserRoute>
                        <AddReview/>
                    </UserRoute>
                ),
            },
            {
                path: "/stock-request",
                element: <CommonRoute>
                    <StockRequest/>
                </CommonRoute>
            },
            {
                path: "/service-request",
                element: (
                    <CommonRoute>
                        <ServiceRequest/>
                    </CommonRoute>
                )
            },
            {
                path: "/products",
                element: <UserRoute>
                    <AllProductContainer/>
                </UserRoute>,
            },
            {
                path: "/review-list",
                element: (
                    <CommonRoute>
                        <Reviews/>
                    </CommonRoute>
                ),
            },
            {
                path: "/order-list",
                element: <CommonRoute>
                    <OrderList/>
                </CommonRoute>,
            },
            {
                path: "/add-user",
                element: (
                    <SuperAdminRoute>
                        <AddUser/>
                    </SuperAdminRoute>
                )
            },
            {
                path: "/revenue",
                element: (
                    <AdminRoute>
                        <Revenue/>
                    </AdminRoute>
                )
            },
            {
                path: "/profile",
                element: <CommonRoute>
                    <Profile/>
                </CommonRoute>
            },
        ],
        errorElement: <NotFound/>
    },
    {
        path: "/login",
        element: <Login/>,
    },
]);
